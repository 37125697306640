@media (min-width: 10em)
  .sm-p0
    padding 0
  .sm-pt0
    padding-top 0
  .sm-pr0
    padding-right 0
  .sm-pb0
    padding-bottom 0
  .sm-pl0
    padding-left 0
  .sm-px0
    padding-left 0
    padding-right 0
  .sm-py0
    padding-top 0
    padding-bottom 0
  .sm-p1
    padding --space-1
  .sm-pt1
    padding-top --space-1
  .sm-pr1
    padding-right --space-1
  .sm-pb1
    padding-bottom --space-1
  .sm-pl1
    padding-left --space-1
  .sm-px1
    padding-left --space-1
    padding-right --space-1
  .sm-py1
    padding-top --space-1
    padding-bottom --space-1
  .sm-p2
    padding --space-2
  .sm-pt2
    padding-top --space-2
  .sm-pr2
    padding-right --space-2
  .sm-pb2
    padding-bottom --space-2
  .sm-pl2
    padding-left --space-2
  .sm-px2
    padding-left --space-2
    padding-right --space-2
  .sm-py2
    padding-top --space-2
    padding-bottom --space-2
  .sm-p3
    padding --space-3
  .sm-pt3
    padding-top --space-3
  .sm-pr3
    padding-right --space-3
  .sm-pb3
    padding-bottom --space-3
  .sm-pl3
    padding-left --space-3
  .sm-px3
    padding-left --space-3
    padding-right --space-3
  .sm-py3
    padding-top --space-3
    padding-bottom --space-3
  .sm-p4
    padding --space-4
  .sm-pt4
    padding-top --space-4
  .sm-pr4
    padding-right --space-4
  .sm-pb4
    padding-bottom --space-4
  .sm-pl4
    padding-left --space-4
  .sm-px4
    padding-left --space-4
    padding-right --space-4
  .sm-py4
    padding-top --space-4
    padding-bottom --space-4
