.title		
	&.-headline
		font-weight 300
		font-size 30px
		color #FFFFFF
		text-align center
		line-height 44px
		
		@media screen and (max-width: 52em)
			line-height 34px
			font-size 22px

	&.-primary
		font-family 'Open Sans Condensed', sans-serif
		font-weight 700
		font-size 40px
		color --green-0
		line-height 40px
		
		@media screen and (max-width: 52em)
			font-size 28px
			line-height 30px
		
		&.-smaller
			font-size 37px
	
	&.-modal
		font-weight 400
		font-size 40px
		color #FFFFFF
		line-height 40px
		text-transform uppercase
	
	&.-steps
		font-family 'Open Sans Condensed', sans-serif
		font-weight 700
		font-size 40px
		color transparent
		line-height 36px
		
		&:before
			text-align left
			box-sizing border-box
			position absolute
			top 0
			left 0
			overflow hidden
			width 0
			transition all 800ms ease-in-out
			content attr(data-hover)
			white-space nowrap
			color white
		
		&.-visible
			&:before
				width 100%

	&.-list
		font-family 'Open Sans Condensed', sans-serif
		font-weight 700
		font-size 22px
		color --green-0
		line-height 30px
		transition all 700ms ease-in-out
		opacity 0
		
		&.-visible
			opacity 1
	
	&.-white
		color white
	
	&.-uppercase
		text-transform uppercase

	&.-footer
		font-weight 300
		font-size 30px
		color --beiche-0
		line-height 41px
		
		&.-small
			font-size 14px
			font-weight bold
			text-transform uppercase
			line-height 26px
