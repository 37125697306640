@media (min-width: 64em)
  .lg-p0
    padding 0
  .lg-pt0
    padding-top 0
  .lg-pr0
    padding-right 0
  .lg-pb0
    padding-bottom 0
  .lg-pl0
    padding-left 0
  .lg-px0
    padding-left 0
    padding-right 0
  .lg-py0
    padding-top 0
    padding-bottom 0
  .lg-p1
    padding --space-1
  .lg-pt1
    padding-top --space-1
  .lg-pr1
    padding-right --space-1
  .lg-pb1
    padding-bottom --space-1
  .lg-pl1
    padding-left --space-1
  .lg-px1
    padding-left --space-1
    padding-right --space-1
  .lg-py1
    padding-top --space-1
    padding-bottom --space-1
  .lg-p2
    padding --space-2
  .lg-pt2
    padding-top --space-2
  .lg-pr2
    padding-right --space-2
  .lg-pb2
    padding-bottom --space-2
  .lg-pl2
    padding-left --space-2
  .lg-px2
    padding-left --space-2
    padding-right --space-2
  .lg-py2
    padding-top --space-2
    padding-bottom --space-2
  .lg-p3
    padding --space-3
  .lg-pt3
    padding-top --space-3
  .lg-pr3
    padding-right --space-3
  .lg-pb3
    padding-bottom --space-3
  .lg-pl3
    padding-left --space-3
  .lg-px3
    padding-left --space-3
    padding-right --space-3
  .lg-py3
    padding-top --space-3
    padding-bottom --space-3
  .lg-p4
    padding --space-4
  .lg-pt4
    padding-top --space-4
  .lg-pr4
    padding-right --space-4
  .lg-pb4
    padding-bottom --space-4
  .lg-pl4
    padding-left --space-4
  .lg-px4
    padding-left --space-4
    padding-right --space-4
  .lg-py4
    padding-top --space-4
    padding-bottom --space-4
