@media screen and (max-width: 52em)
	.sm-to-hide
		display none

	.block-max-width
		max-width 100%

	.home-step-2, .home-step-3
		margin-top 30px

	.shaped-block
		&.-natural
			position absolute
			z-index -1
		
	.machine-1
		position relative
		width 90%
		bottom -60px
		right 50px
	
	.stone
		position relative
		width 70%
		left -10px

	.link
		&.-slider
			font-size 18px
			line-height 20px

	.slider-wrapper
		height 200px

@media screen and (max-width: 1362px)
	.name-wrapper
		left auto
		right 20px
		bottom 40px	

	.stone
		width 90%
		left -90px

		@media screen and (min-width: 410px) and (max-width: 832px)
			width 65%
			left 20px

@media screen and (min-width: 833px) and (max-width: 1400px)
	.header
		padding-left 10px
		padding-right 10px
	
	.ipad-pl
		padding-left 10px
	
	.ipad-pr
		padding-right 10px
