.text		
	font-weight 300
	font-size 14px
	color --gray-0
	line-height 26px

	&.-white
		color white

	&.-bold
		font-weight 700

	&.-footer
		font-weight 300
		font-size 14px
		color #D8D8D8
		line-height 26px
