--green-check = #7ac142

--curve-anim = cubic-bezier(0.650, 0.000, 0.450, 1.000)

.checkmark
  width 30px
  height 30px
  border-radius 50%
  display block
  stroke-width 5
  stroke white
  stroke-miterlimit 10
  box-shadow inset 0px 0px 0px --green-0
  
  &.-animated
    animation fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both

.checkmark__circle
  stroke-dasharray 166
  stroke-dashoffset 166
  stroke-width 2
  stroke-miterlimit 10
  stroke --green-0
  fill none
  
  &.-animated
    animation stroke .6s --curve-anim forwards

.checkmark__check
  transform-origin 50% 50%
  stroke-dasharray 48
  stroke-dashoffset 48
  
  &.-animated
    animation stroke .3s --curve-anim .8s forwards

@keyframes stroke
  100%
    stroke-dashoffset 0

@keyframes scale
  0%, 100%
    transform none
  50%
    transform scale3d(1.1, 1.1, 1)

@keyframes fill
  100%
    box-shadow inset 0px 0px 0px 30px --green-0
