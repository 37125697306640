.button
	outline none
	
	&.-home
		background white
		box-shadow 0 0 25px 0 rgba(0, 0, 0, .2)
		border-radius 37px
		text-align center
		max-width 425px
		margin-left auto
		margin-right auto
		position relative
		top -52px
		
		.line-1, .line-2
			font-weight 400
			line-height 20px
			font-size 16px
			text-transform uppercase
			
		.line-1
			color --green-2

		.line-2
			color --beiche-0
			font-size 14px
		
		&:hover
			background-color --green-0
			
			.line-1
				color white
	
	&.-modal
		top auto
		bottom -35px

	&.-submit
		background #D4BFA3
		border-radius 40px
		font-weight bold
		font-size 12px
		color white
		letter-spacing 2px
		text-align center
		text-transform uppercase
		cursor pointer
		border none

		&:hover
			background --green-0
