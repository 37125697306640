#copyright #logo_aki {
  display: block;
  opacity: 0.5;
  height: 34px;
  width: 116px;
  margin: auto;
  background: url("../images/aki_logo_white.png") no-repeat 0 0;
  -moz-transition: opacity 500ms ease;
  -o-transition: opacity 500ms ease;
  -webkit-transition: opacity 500ms ease;
  transition: opacity 500ms ease;
  float: right;
}
#copyright #logo_aki:after {
  content: '';
  display: block;
  width: 0;
  height: 100%;
  background: url("../images/aki_logo_hover.png") no-repeat 0% 0;
  transition: all 500ms ease;
}
#copyright #logo_aki:hover {
  opacity: 1;
}
#copyright #logo_aki:hover:after {
  width: 100%;
}
