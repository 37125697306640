.font-family-inherit
  font-family inherit
.font-size-inherit
  font-size inherit
.text-decoration-none
  text-decoration none

.bold
  font-weight var(--bold-font-weight, bold)
.regular
  font-weight normal
.italic
  font-style italic
.caps
  text-transform uppercase
  letter-spacing var(--caps-letter-spacing)

.left-align
  text-align left
.center
  text-align center
.right-align
  text-align right
.justify
  text-align justify

.nowrap
  white-space nowrap
.break-word
  word-wrap break-word

.line-height-1
  line-height var(--line-height-1)
.line-height-2
  line-height var(--line-height-2)
.line-height-3
  line-height var(--line-height-3)
.line-height-4
  line-height var(--line-height-4)

.list-style-none
  list-style none
.underline
  text-decoration underline

.truncate
  max-width 100%
  overflow hidden
  text-overflow ellipsis
  white-space nowrap

.list-reset
  list-style none
  padding-left 0

:root
  --line-height-1 1
  --line-height-2 1.125
  --line-height-3 1.25
  --line-height-4 1.5
  --caps-letter-spacing .2em
  --bold-font-weight bold
