.link
	font-weight 300
	font-size 14px
	color #0084A8
	line-height 26px
	
	&:hover
		text-decoration none
	
	&.-lang
		font-family 'Open Sans', sans-serif
		font-weight 600
		font-size 13px
		line-height 1.5
		color #BEBEBE
		
		&.-active, &:hover
			color white
		
		&.-separator:hover
			color #BEBEBE
	
	&.-nav
		font-family 'Open Sans', sans-serif
		font-weight 700
		font-size 13px
		color --green-0
		text-transform uppercase
		letter-spacing 0.4px
		border-radius 37px
		position relative
		transition all 150ms ease-in-out
		
		&.-cta
			border 2px solid --green-0
		
		&:after
			content ''
			z-index -1
			opacity 0
			border-radius 37px
			width 100%
			height 100%
			transform scale(.1)
			position absolute
			left 0
			top 0
			background-color --green-0
			transition all 150ms ease-in-out
			
		&:hover, &.-active
			color white
			border-color white
			
			&:after
				opacity 1
				-moz-transform scale(1)
				-webkit-transform scale(1)
				-o-transform scale(1)
				-ms-transform scale(1)
				transform scale(1)
	
	&.-footer
		font-weight bold
		font-size 30px
		color white
		line-height 41px
		
		@media screen and (max-width: 52em)
			font-size 20px
		
		&:hover
			color --beiche-0

	&.-beige
		color --beiche-0
	
	&.-slider
		font-family 'Open Sans Condensed', sans-serif
		font-weight bold
		font-size 40px
		color #FFFFFF
		text-align right
		line-height 40px
		text-transform uppercase
		cursor default
		
		&.-active
			color --green-2

.link-wrap
	font-weight 300
	font-size 14px
	color #0084A8
	
