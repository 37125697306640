.hide
  position absolute
  height 1px
  width 1px
  overflow hidden
  clip rect(1px, 1px, 1px, 1px)

@media screen and (min-width: 10em)
  .sm-hide 
    display none

@media screen and (min-width: 52em)
  .md-hide 
    display none

@media (min-width: 64em)
  .lg-hide
    display none

.display-none
  display none
