.footer
	margin-top -90px
	z-index 200
	
	.shape-footer
		width 100%
		height 100%
		position absolute
		z-index 100
		background-repeat no-repeat
		background-size cover
		mask-image url('../images/deco/shape-footer.svg')
		mask-repeat no-repeat
		mask-size cover
		mask-position top center
		background --green-0
		// margin-top -25px
