.remodal
	background #005F78
	box-shadow 0 0 30px 0 rgba(0,0,0,0.50)
	padding 35px 35px 0 35px

html.remodal-is-locked
	overflow visible!important

.remodal-overlay
	background-color rgba(0, 0, 0, .5)

.close-modal
	width 38px
	height 38px
	position absolute
	right -45px
	top -45px
	background-image url('../images/deco/close-btn.png')
	background-size 100% 100%
	
	
.modal-content
	p, ul li, ol li, span, h1, h2, h3, h4, h5, h6
		font-weight 300
		font-size 14px
		color #FFFFFF
		line-height 26px
		margin 0
		padding 0
		text-align left
	
	p
		margin-bottom 20px
	
	ul
		margin-bottom 30px
		margin-left 30px
		
		li
			text-align left
