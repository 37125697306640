.p0
  padding 0
.pt0
  padding-top 0
.pr0
  padding-right 0
.pb0
  padding-bottom 0
.pl0
  padding-left 0
.px0
  padding-left 0
  padding-right 0
.py0
  padding-top 0
  padding-bottom 0

.p1
  padding --space-1
.pt1
  padding-top --space-1
.pr1
  padding-right --space-1
.pb1
  padding-bottom --space-1
.pl1
  padding-left --space-1
.py1
  padding-top --space-1
  padding-bottom --space-1
.px1
  padding-left --space-1
  padding-right --space-1

.p2 
  padding --space-2
.pt2
  padding-top --space-2
.pr2
  padding-right --space-2
.pb2
  padding-bottom --space-2
.pl2
  padding-left --space-2
.py2
  padding-top --space-2
  padding-bottom --space-2
.px2
  padding-left --space-2
  padding-right --space-2

.p3
  padding --space-3
.pt3
  padding-top --space-3
.pr3
  padding-right --space-3
.pb3
  padding-bottom --space-3
.pl3
  padding-left --space-3
.py3
  padding-top --space-3
  padding-bottom --space-3
.px3
  padding-left --space-3
  padding-right --space-3

.p4
  padding --space-4
.pt4
  padding-top --space-4
.pr4
  padding-right --space-4
.pb4
  padding-bottom --space-4
.pl4
  padding-left --space-4
.py4
  padding-top --space-4
  padding-bottom --space-4
.px4
  padding-left --space-4
  padding-right --space-4
