@media screen and (min-width: 52em)

  .md-col
    float left
    box-sizing border-box

  .md-col-right
    float right
    box-sizing border-box

  .md-col-1
    width calc(1/12 * 100%)

  .md-col-2
    width calc(2/12 * 100%)

  .md-col-3
    width calc(3/12 * 100%)

  .md-col-4
    width calc(4/12 * 100%)

  .md-col-5
    width calc(5/12 * 100%)

  .md-col-6
    width calc(6/12 * 100%)

  .md-col-7
    width calc(7/12 * 100%)

  .md-col-8
    width calc(8/12 * 100%)

  .md-col-9
    width calc(9/12 * 100%)

  .md-col-10
    width calc(10/12 * 100%)

  .md-col-11
    width calc(11/12 * 100%)

  .md-col-12
    width 100%
