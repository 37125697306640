.shape-textures
	background-repeat no-repeat
	background-size cover
	mask-image url('../images/deco/shape-textures.svg')
	mask-repeat no-repeat
	mask-size cover
	mask-position center center
	margin-top -140px
	height 460px
	
.shaped-block
	background-repeat no-repeat
	background-size cover
	mask-image url('../images/deco/shape-extrenal-stone.svg')
	mask-repeat no-repeat
	mask-size cover
	mask-position center center
	
	&.-natural
		mask-position bottom center
		mask-image url('../images/deco/shape-home-natural.svg')
		background rgb(51,127,147)
		background -moz-linear-gradient(top, rgba(51,127,147,1) 0%, rgba(17,105,128,1) 100%)
		background -webkit-linear-gradient(top, rgba(51,127,147,1) 0%,rgba(17,105,128,1) 100%)
		background linear-gradient(to bottom, rgba(51,127,147,1) 0%,rgba(17,105,128,1) 100%)
		width 100%
		height 100%
		
	
	&.-right
		mask-position top center
		mask-image url('../images/deco/shape-home-natural-right.svg')
		background rgb(51,127,147)
		background -moz-linear-gradient(top,  rgba(51,127,147,1) 0%, rgba(0,95,120,1) 100%)
		background -webkit-linear-gradient(top,  rgba(51,127,147,1) 0%,rgba(0,95,120,1) 100%)
		background linear-gradient(to bottom,  rgba(51,127,147,1) 0%,rgba(0,95,120,1) 100%)
		margin-top -14px
	
	&.-left
		mask-position top center
		mask-image url('../images/deco/shape-home-natural-left.svg')

	&.-last-right
		mask-position bottom center
		mask-image url('../images/deco/shape-home-last-right.svg')
		// filter: drop-shadow(1px 1px 4px rgba(0,0,0,0.75));

	&.-last-left
		mask-position bottom center
		mask-image url('../images/deco/shape-home-last-left.svg')
		margin-top -25px