@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700&display=swap');

@import './lib/aos/aos.css'
@import './lib/remodal/remodal-default-theme.css'
@import './lib/remodal/remodal.css'

@import './layout/_variables'
@import './lib/basscss/basscss'
@import './layout/_base'

@import './layout/_header'
@import './layout/_headline'
@import './layout/_title'
@import './layout/_text'
@import './layout/_list'
@import './layout/_checklist'
@import './layout/_link'
@import './layout/_shape'
@import './layout/_button'
@import './layout/_textfield'
@import './layout/_form'
@import './layout/_signature'
@import './layout/_remodal'
@import './layout/_footer'

.step-wrapper
	background-color --beiche-0
	margin-top -80px
	z-index -1

.steps-bg
	background-image url('../images/deco/bg-steps.png')
	position absolute
	left 0
	top 0
	width 100%
	height 100%
	z-index 0

.home-gradient
	background rgb(51,127,147);
	background -moz-linear-gradient(top,  --green-1 1%, --green-0 99%)
	background -webkit-linear-gradient(top,  --green-1 1%,--green-0 99%)
	background linear-gradient(to bottom,  --green-1 1%,--green-0 99%)
	
.home-black
	background-color #2F2F2F

.home-wrapper
	margin-top -50px
	z-index 900

.machine-1
	position absolute
	right -50px
	bottom -35px

.stone
	position absolute
	left -170px
	bottom 0
	display none
	
	&.-sand
		display block

.name-wrapper
	position absolute
	left 450px
	bottom 100px

.home-step-2
	margin-top 226px

.home-step-3
	margin-top 385px

.paves-home
	max-width 100%

.dots-step
	position absolute
	height 144px
	display block
	background-image url('../images/deco/dots-steps.png')
	width 0
	transition all 600ms ease-in-out
	
	&.-step-1
		background-repeat no-repeat
		left 250px
		top 70px
		
		&.-visible
			width 234px
	
	&.-step-2
		background-repeat no-repeat
		left 150px
		top 5px
		
		&.-visible
			width 234px
	
	&.-step-3
		width 0
		height 151px
		background-repeat no-repeat
		background-image url('../images/deco/dots-steps-last.png')
		left 185px
		top -107px
		
		&.-visible
			width 228px
		
	@media screen and (max-width: 1246px)
		display none

.arrow-step
	position absolute
	width 37px
	height 37px
	background-repeat no-repeat
	transition all 600ms ease-in-out
	background-image url('../images/deco/arrow-step-dots.png')
	right -50px
	top -155px
	opacity 0
	
	&.-visible
		opacity 1
	
	@media screen and (max-width: 1246px)
		display none

.slider-wrapper
	position relative
	z-index 100

.accredited-wrapper
	margin-top -100px
	padding-top 150px
	padding-bottom 80px
	clip-path polygon(0 0, 100% 0, 100% 100%, 0 92%)

.belac-logo
	width 180px
	height 220px
	margin-top 7px

@import './layout/_responsive'
