.textfield
	border 1px solid #EEEEEE
	border-radius 3px
	font-weight 300
	font-size 14px
	color #818181
	line-height 26px
	width 100%
	max-width 100%


::-webkit-input-placeholder
	font-family 'Montserrat', sans-serif
  
::-moz-placeholder
	font-family 'Montserrat', sans-serif
  
:-ms-input-placeholde
	font-family 'Montserrat', sans-serif
  
:-moz-placeholder
	font-family 'Montserrat', sans-serif
