.relative
  position relative

.absolute
  position absolute

.fixed
  position fixed

.top-0
  top 0

.right-0
  right 0

.bottom-0
  bottom 0

.left-0
  left 0
