body
  margin 0
  padding 0!important
  font-family 'Montserrat', sans-serif
  overflow-x hidden

a
  outline 0
  text-decoration none

*
  box-sizing border-box
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale

.container-width
  width 100%
  max-width 1200px
  margin-left auto
  margin-right auto
  
  &.-large
    max-width 1600px
    

.bg-cover
  background-size cover
  background-repeat no-repeat
  background-position center center

.z-bottom, .z-medium, .z-top
  position relative
  
.z-bottom
  z-index 1

.z-medium
  z-index 300

.z-top
  z-index 900

.bg-white
  background-color white
  
.block-max-width
  max-width 612px
  width 100%

.margin-neg
  margin-top -80px

  &.-tiny
    margin-top -30px
  
  &.-textures
    margin-top -45px

.img-full
  width 100%
  height auto
  
.img-cover-full
  width 100%
  height 100%
