.headline
	overflow hidden
	height 100vh
	width 100vw
	max-width 100%
	
	.logo
		width 318px
		height 80px
	
	.logo-headline
		bottom -73vh
		opacity 0
	
	.sky-headline, .rock-headline
		width 100vw
		height 100vh
		position absolute
		top 0
		left 0
		z-index 100
	
	.sky-headline
		background-image url('../images/deco/bg-headline-sky.png')

	.rock-headline
		background-image url('../images/deco/bg-headline-rock.png')
		background-repeat no-repeat
		// background-attachment fixed
		background-position center center
		background-size cover
		top auto
		bottom -250px
		z-index 800
		pointer-events none
		
		@media screen and (max-width: 1500px)
			bottom -100px
		
		@media screen and (max-width: 1000px)
			bottom -200px
			
		@media screen and (max-width: 650px)
			bottom -260px
		
	
	.title.-headline
		bottom -65vh
		opacity 0


// @keyframes moverock
// 	from
// 		background-position center bottom -290px
		
// 	to
// 		background-position center bottom -200px
