.header
	background #FFFFFF
	box-shadow 0 0 35px 0 rgba(0,0,0,0.20)
	position relative
	z-index 999
	opacity 0
	position absolute
	left 0
	top 0
	width 100%
	transition all 350ms ease
	
	.logo-header
		width 134px
		height 32px
	
	&.-visible
		opacity 1

	&.-fixed
		position: fixed;
		opacity 1

body.sticky
	.header
		position fixed
		top 0
		margin-top 0
		left 0
